var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "box-wrapper" }, [
    _c("div", { staticClass: "box-content" }, [
      _c(
        "div",
        { staticClass: "mt-4 mb-4 text-center" },
        [
          _c("h4", { staticClass: "title" }, [
            _vm._v(_vm._s(_vm.$t("ERROR.CANNOT_DOWNLOAD"))),
          ]),
          _c(
            "router-link",
            { attrs: { variant: "primary", to: { name: "Login" } } },
            [_vm._v(_vm._s(_vm.$t("BACK")))]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }