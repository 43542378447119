<template>
  <div class="box-wrapper">
    <div class="box-content">
      <div class="mt-4 mb-4 text-center">
        <h4 class="title">{{ $t("ERROR.CANNOT_DOWNLOAD") }}</h4>
        <b-button variant="primary" @click="close">{{
          $t("CLOSE")
        }}</b-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    close(){
      window.close();
    }
  },
  computed: {},
  mounted() {},
};
</script>
<style></style>
